.container {
    display: flex;
    gap: var(--mantine-spacing-lg);
    justify-content: center;
    align-content: flex-start;
    margin: 20px auto 20px auto;
    max-width: 650px;
    height: 100%;
}

.profileContainer {
    position: sticky;
    top: 0;
    max-width: 440px;
    width: 100%;
}
