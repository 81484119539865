.container {
    display: flex;
    gap: var(--mantine-spacing-lg);
    justify-content: center;
    align-content: flex-start;
    margin: 20px auto 100px auto;
    max-width: 1060px;
    height: 100%;
}

.projects {
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding-bottom: 48px;
}

.projectDescription {
    font-family: SFPro, serif;
    padding: 0 10px;
    /*text-indent: 20px;*/
}

.projectPage {
    animation: showDown 1.5s cubic-bezier(.25,.75,.5,1.25) forwards;
    padding-bottom: 48px;
}
.projectImage {
    margin-bottom: 25px;

}

.projectImage img {
    width: 650px;
    height: auto;
    border-radius: 20px;
    border: 3px solid #2E2E2E;
}

.projectImage div {
    border-radius: 20px;
}

.projectImagePreview {
    max-height: 90vh;
    width: auto;
}

.projectVideo {
    width: 650px;
    height: auto;
    border-radius: 20px;
}

.projectVideo video {
    border-radius: 20px;
    border: 3px solid #2E2E2E;
}

.projectBadge {
    font-weight: 500;
    background-color: rgba(226, 232, 240, 0.16);
    width: max-content;
    box-sizing: content-box;
    padding: 2px 5px;
    border-radius: 5px;
    text-indent: 0;
    color: white;
}

.projectBlockTitle {
    font-family: MPlus, serif;
    font-size: 20px;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 6px;
    text-decoration-color: #525252;
    text-decoration-thickness: 4px;
    margin-bottom: 10px;
    color: white;
}


.nestedList {
    margin: 0;
    padding: 0;
    outline: none;
    counter-reset: item;
    list-style: none;
    text-indent: 0 !important;
}

.nestedListItem {
    line-height: 1.6em;
    display: flex;
    margin: 2px 25px;
}

.nestedListItem::before {
    counter-increment: item;
    margin-right: 5px;
    white-space: nowrap;
}

.nestedListItemUnordered::before  {
    content: "•";
    color: #737373;
    font-size: 18px;
}
