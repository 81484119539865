.container {
    max-width: 1060px;
    width: 100%;
    height: 100%;
    align-items: center;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 20px;
}

@media (width < 1024px) {
    .container {
        grid-template-columns: repeat(2, 1fr);
    }
}