.container {
    background-color: var(--mantine-color-dark-6) !important;
}

.badge {
    border: 1px solid var(--mantine-color-dark-4) !important;
}

.dilimeter {
    width: 3px;
    height: 3px;
    background-color: var(--mantine-color-dark-1) !important;
    border-radius: 100%;
}
