.container {
    background-color: var(--mantine-color-dark-6) !important;
}

.card {
    width: 64px;
    height: 64px;
    background-color: #242428;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(255, 255, 255, .1);
    padding: 5px;
    border-radius: 10px;
    transition: all 0.5s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
}
