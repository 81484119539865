@keyframes rotate {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(20deg); }
    50% { transform: rotate(0deg); }
    75% { transform: rotate(20deg); }
    100% { transform: rotate(0deg);}
}

.container {
    background-color: var(--mantine-color-dark-6) !important;
}

.badge {
    text-transform: full-width !important;
    font-family: MPlus, serif !important;
}

.emoji {
    width: min-content;
    transition: all 0.3s ease-in-out;
    animation: rotate 2s ease-in-out infinite;
    transform-origin: 28px 30px
}
