.container {
    margin: 20px auto 100px auto;
    max-width: 1060px;
}

.postContainer {
    margin: 20px auto 0 auto;
    max-width: 650px;
    height: 100%;
}

.post__video {
    width: 650px;
    height: auto;
    border-radius: 15px;
    display: flex;
    justify-content: center;
}

.post__video video {
    border-radius: 15px;
    border: 3px solid #2E2E2E;
}

.projectsTitle {
    font-family: MPlus, serif;
    font-size: 32px;
    margin: 50px 0;
    text-align: center;
    color: white;
}

.projectImage {
    border: 1px solid var(--mantine-color-dark-4)
}
