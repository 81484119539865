.container {
    display: flex;
    gap: var(--mantine-spacing-lg);
    justify-content: center;
    align-content: flex-start;
    margin: 20px auto 20px auto;
    max-width: 1060px;
    height: 100%;
    padding: 0 20px;
}

.profileContainer {
    position: sticky;
    /*max-width: 500px;*/
    width: 100%;
    height: fit-content;
    top: 80px;
}

@media (width < 1024px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .profileContainer {
        top: 0;
        position: relative;
    }
}