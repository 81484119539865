.container {
    background-color: var(--mantine-color-dark-6) !important;
}

.badge {
    text-transform: full-width !important;
    font-family: MPlus, serif !important;
}

.content {
    white-space: pre-line;
}
